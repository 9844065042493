import "./src/assets/styles/Custom.css"
import "./node_modules/slick-carousel/slick/slick.css"
import "./node_modules/slick-carousel/slick/slick-theme.css"
import "./src/gatsby-plugin-theme-ui/tokens.css"

import React from "react"
import Layout from "./src/templates/layout/Layout"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
