export const DEALERSHIP_NAME = "Doozy Auto"

export const TAGLINE = " Quality vehicles at great prices!"

export const ADDRESS = "601 Dixie Hwy, Pompano Beach, FL 33060"

export const PHONE_NUMBER = "954-440-0262"

export const EMAIL = "doozyautoscanner@gmail.com"

export const MAP_URL =
  "https://www.google.com/maps/dir//601+N+Dixie+Hwy,+Pompano+Beach,+FL+33060/@26.2372265,-80.127087,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x88d902ec12837391:0xaa5c1c5e20498871!2m2!1d-80.1248983!2d26.2372217!3e0?entry=ttu"

export const MAP_LINK_URL =
  "https://www.google.com/maps/place/461+W+Valencia+Dr+n,+Fullerton,+CA+92832/@33.8665132,-117.9350873,17z/data=!3m1!4b1!4m5!3m4!1s0x80dd2a0595e8f663:0xffdecaf9a23b0aeb!8m2!3d33.8665088!4d-117.9325124?entry=ttu"

export const HOURS = [
  { day: "M-F", hours: "9AM - 6PM" },
  { day: "Sat", hours: "8AM - 5PM" },
  { day: "Sun", hours: "by appointment only" },
]

export const DISCLAIMER = `The estimated monthly payments and estimated down
payments listed on this inventory page are based on a 720 credit
score and a 72 month term with $100,000 annual gross income.
Actual terms may vary based on individual creditworthiness, loan
amount, and length of term. Prices listed may exclude additional
fees and taxes. Please contact us for more information on
financing options and terms.
`

export const TESTIMONIALS = [
  {
    name: "John",
    title: "Satisfied Customer",
    testimonial:
      "The dealership was very helpful, and the staff were friendly and knowledgeable. I got a great deal on my new car, and the process was seamless and quick.",
    rating: 5,
  },
  {
    name: "Mary",
    title: "Happy Buyer",
    testimonial:
      "The customer service here is excellent. They helped me find the perfect car for my needs and budget. Their transparency and honesty were much appreciated.",
    rating: 4,
  },
  {
    name: "Alice",
    title: "Long-time Customer",
    testimonial:
      "I have been coming to this dealership for years, and they never disappoint. They have a wide variety of cars and are always upfront about costs and features.",
    rating: 5,
  },
  {
    name: "Bob",
    title: "First-time Buyer",
    testimonial:
      "As a first-time car buyer, I appreciated the patience and guidance the dealership provided. They made sure I understood everything and didn’t pressure me.",
    rating: 4,
  },
  {
    name: "Charlie",
    title: "Returning Customer",
    testimonial:
      "This is the third car I’ve bought from this dealership, and every experience has been great. The staff is friendly, and their after-sales service is superb.",
    rating: 5,
  },
  {
    name: "Donna",
    title: "Satisfied Customer",
    testimonial:
      "They made the process of trading in my old car for a new one so easy. They gave me a fair price and handled all the paperwork efficiently.",
    rating: 5,
  },
  {
    name: "Edward",
    title: "Happy Buyer",
    testimonial:
      "The dealership was able to answer all my questions and provide me with all the information I needed. Their level of service and commitment is commendable.",
    rating: 4,
  },
  {
    name: "Fiona",
    title: "First-time Buyer",
    testimonial:
      "Their selection of cars is excellent, and the staff was very patient and knowledgeable. I never felt rushed or pushed into making a decision.",
    rating: 4,
  },
  {
    name: "George",
    title: "Long-time Customer",
    testimonial:
      "This dealership always provides top-notch service. Their staff is efficient and friendly, and they have always been able to meet my needs and exceed my expectations.",
    rating: 5,
  },
  {
    name: "Helen",
    title: "Returning Customer",
    testimonial:
      "The finance team at this dealership is incredible. They helped me secure a fantastic financing deal and made sure I understood all the terms and conditions.",
    rating: 5,
  },
]
