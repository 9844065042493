import React from "react"
import PropTypes from "prop-types"
import { Box, ThemeProvider } from "theme-ui"
import { AnimatePresence, motion } from "framer-motion"
import Header from "../../components/header/Header"
import styles from "./layoutStyles"
import theme from "../../gatsby-plugin-theme-ui"
import TopBar from "../../components/topBar/TopBar"
import Footer from "../../components/footer/Footer"
import BottomBar from "../../components/bottomBar/BottomBar"

const Layout = ({ children, location }) => (
  <ThemeProvider theme={theme}>
    <Box sx={styles.layout}>
      <TopBar />
      <Header />
      <Box as="main" sx={styles.main}>
        <AnimatePresence mode="wait">
          <motion.div
            key={location.pathname} // Make sure to use a unique key
            initial={{ opacity: 0 }} // Start from a transparent state
            animate={{ opacity: 1 }} // Fade in to full opacity
            exit={{ opacity: 0 }} // Fade out
            transition={{ duration: 0.1 }} // Transition duration
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </Box>
      <Footer />
      <BottomBar />
    </Box>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired, // Add location to PropTypes
}

export default Layout
